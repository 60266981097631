import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input, inject, input } from '@angular/core';
import { UiRepository } from '@big-direkt/state/ui';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { IconBigMediumSchliessenKreis, IconComponent } from '@big-direkt/utils/icons';
import { type TopBarModel } from '@big-direkt/utils/shared';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-top-bar',
    templateUrl: './top-bar.component.html',
    imports: [IconComponent, TranslocoPipe, UiButtonComponent],
    animations: [
        trigger('leaveAnimation', [
            transition(':leave', [
                style({
                    height: '*',
                    opacity: 1,
                }),
                animate('150ms', style({
                    height: '0px',
                    opacity: 0,
                })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
    private readonly uiRepository = inject(UiRepository);
    public readonly iconClose = IconBigMediumSchliessenKreis;
    public readonly items = input<TopBarModel[]>([]);

    @HostBinding('class') @Input() public classList = 'block bg-white z-(--z-top-bar) print:hidden';

    public closeTopBar(index: number): void {
        this.items().splice(index, 1);
        this.uiRepository.setIsTopBarShown(this.items().length > 0);
    }
}
