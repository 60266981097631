import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService, ErrorHandlingService } from '@big-direkt/utils/environment';
import { type Translation, type TranslocoLoader } from '@jsverse/transloco';
import { catchError, type Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoLoaderService implements TranslocoLoader {
    private readonly environment = inject(EnvironmentService);
    private readonly errorHandling = inject(ErrorHandlingService);
    private readonly httpClient = inject(HttpClient);

    public getTranslation(lang: string): Observable<Translation> {
        return this.httpClient
            .get(`${this.environment.baseHref}/assets/i18n/${lang}.vendor.json`)
            .pipe(catchError(error => this.errorHandling.catchError(error)));
    }
}
