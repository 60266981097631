/* eslint-disable max-lines */
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { type ApplicationConfig, ErrorHandler, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withEnabledBlockingInitialNavigation, withPreloading } from '@angular/router';
import { ChatClientModule } from '@big-direkt/chat-client';
import { provideNodeBigError } from '@big-direkt/error-handling';
import {
    provideFormViewAddress,
    provideFormViewAlert,
    provideFormViewCheckboxes,
    provideFormViewCheckbox,
    provideFormViewComposite,
    provideFormViewContactFormSearch,
    provideFormViewContainer,
    provideFormViewCurrencyInput,
    provideFormViewDateList,
    provideFormViewDate,
    provideFormViewDateRange,
    provideFormViewDateTime,
    provideFormViewElementsHolder,
    provideFormViewEmbeddedForm,
    provideFormViewFieldGroup,
    provideFormViewFieldSet,
    provideFormViewFileUpload,
    provideFormViewForm,
    provideFormViewFormRelation,
    provideFormViewHidden,
    provideFormViewHin,
    provideFormViewIFrame,
    provideFormViewItemCollection,
    provideFormViewItem,
    provideFormViewLabel,
    provideFormViewMarkup,
    provideFormViewMobileAppInfo,
    provideFormViewMultipleItem,
    provideFormViewMultiple,
    provideFormViewNavigationButton,
    provideFormViewNumberField,
    provideFormViewOneTimeToken,
    provideFormViewPage,
    provideFormViewPasswordConfirm,
    provideFormViewPassword,
    provideFormViewPdfDownload,
    provideFormViewPreview,
    provideFormViewPrivacyPolicy,
    provideFormViewProgressBar,
    provideFormViewQuantitySelect,
    provideFormViewRadio,
    provideFormViewRadios,
    provideFormViewSearchableSelect,
    provideFormViewSelect,
    provideFormViewTextarea,
    provideFormViewTextfield,
    provideFormViewTime,
    provideFormViewTimeRange,
} from '@big-direkt/form/view';
import { JsonApiClientModule } from '@big-direkt/json-api-client';
import { NavService } from '@big-direkt/nav';
import {
    provideNodeBigAccordion,
    provideNodeBigAccordionItem,
    provideNodeBigArticle,
    provideNodeBigDashboard,
    provideNodeBigEpq,
    provideNodeBigForm,
    provideNodeBigFormOverview,
    provideNodeBigLegal,
    provideNodeBigMyBig,
    provideNodeBigNews,
    provideNodeBigPersonalData,
    provideNodeBigPublicDelivery,
    provideNodeBigStartPage,
    provideNodeBigTaggedArticle,
    provideNodeBigTaggedArticleOverview,
    provideNodeBigTemplate,
    provideNodeBigTopBar,
    provideNodeQuickLink,
} from '@big-direkt/nodes';
import { provideAccordionParagraph } from '@big-direkt/paragraphs/accordion';
import { provideAppliancesSearchParagraph } from '@big-direkt/paragraphs/appliances-search';
import { provideBackLinkParagraph } from '@big-direkt/paragraphs/back-link';
import { provideBgmButtonParagraph } from '@big-direkt/paragraphs/bgm-button';
import { provideBigtionaerParagraph } from '@big-direkt/paragraphs/bigtionaer';
import { provideButtonParagraph } from '@big-direkt/paragraphs/button';
import { provideC2aBannerParagraph } from '@big-direkt/paragraphs/c2a-banner';
import { provideCardGroupParagraph } from '@big-direkt/paragraphs/card-group';
import { provideChatParagraph } from '@big-direkt/paragraphs/chat';
import { provideCoBrowsingButtonParagraph } from '@big-direkt/paragraphs/co-browsing-button';
import { provideContactDataParagraph } from '@big-direkt/paragraphs/contact-data';
import { provideCookiebotParagraph } from '@big-direkt/paragraphs/cookiebot';
import { provideCustomerSegmentLinksParagraph } from '@big-direkt/paragraphs/customer-segment-links';
import { provideDashboardBigtionaerParagraph } from '@big-direkt/paragraphs/dashboard-bigtionaer';
import { provideFilterableLinkListParagraph } from '@big-direkt/paragraphs/filterable-link-list';
import { provideFormOverviewParagraph } from '@big-direkt/paragraphs/form-overview';
import { provideGapRemoverParagraph } from '@big-direkt/paragraphs/gap-remover';
import { provideHeadingParagraph } from '@big-direkt/paragraphs/heading';
import { provideIframeParagraph } from '@big-direkt/paragraphs/iframe';
import { provideImageParagraph } from '@big-direkt/paragraphs/image';
import { provideImageCreditsParagraph } from '@big-direkt/paragraphs/image-credits';
import { provideInstructionParagraph } from '@big-direkt/paragraphs/instruction';
import { provideInsuranceServicesParagraph } from '@big-direkt/paragraphs/insurance-services';
import { provideLinkParagraph } from '@big-direkt/paragraphs/link';
import { provideLinkListParagraph } from '@big-direkt/paragraphs/link-list';
import { provideListParagraph } from '@big-direkt/paragraphs/list';
import { provideMyBigGeneralParagraph } from '@big-direkt/paragraphs/mybig-general';
import { provideMyBigInboxParagraph } from '@big-direkt/paragraphs/mybig-inbox';
import { provideMyBigMedicalAppointmentParagraph } from '@big-direkt/paragraphs/mybig-medical-appointment';
import { provideMyBigSickNotesParagraph } from '@big-direkt/paragraphs/mybig-sick-notes';
import { provideNewsTeaserParagraph } from '@big-direkt/paragraphs/news-teaser';
import { providePersonalDataParagraph } from '@big-direkt/paragraphs/personal-data';
import { providePublicDeliveryParagraph } from '@big-direkt/paragraphs/public-delivery';
import { provideQuickLinkListParagraph } from '@big-direkt/paragraphs/quick-link-list';
import { provideSearchBoxParagraph } from '@big-direkt/paragraphs/search-box';
import { provideSearchableLinkListParagraph } from '@big-direkt/paragraphs/searchable-link-list';
import { provideServiceToolParagraph } from '@big-direkt/paragraphs/service-tool';
import { provideSubjectAreasParagraph } from '@big-direkt/paragraphs/subject-areas';
import { provideTableParagraph } from '@big-direkt/paragraphs/table';
import { provideTextParagraph } from '@big-direkt/paragraphs/text';
import { provideTextButtonParagraph } from '@big-direkt/paragraphs/text-button';
import { provideUserDataParagraph } from '@big-direkt/paragraphs/user-data';
import { provideUserDetailsParagraph } from '@big-direkt/paragraphs/user-details';
import { provideVideoGroupParagraph } from '@big-direkt/paragraphs/video-group';
import { provideWebformParagraph } from '@big-direkt/paragraphs/webform';
import { RestApiClientModule } from '@big-direkt/rest-api-client';
import { provideAdvantageCalculatorServiceTool } from '@big-direkt/service-tools/advantage-calculator';
import { provideCoPaymentCalculatorServiceTool } from '@big-direkt/service-tools/co-payment-calculator';
import { provideContributionCalculatorServiceTool } from '@big-direkt/service-tools/contribution-calculator';
import { provideDiabetesDiagnosisServiceTool } from '@big-direkt/service-tools/diabetes-diagnosis';
import { provideDiagnosisCodeSearch } from '@big-direkt/service-tools/diagnosis-code-search';
import { provideFamilyHealthRetreatSearchServiceTool } from '@big-direkt/service-tools/family-health-retreat-search';
import { provideHomeopathyDoctorSearchServiceTool } from '@big-direkt/service-tools/homeopathy-doctor-search';
import { providePreventionCheckServiceTool } from '@big-direkt/service-tools/prevention-check';
import { provideSpecialistSearchServiceTool } from '@big-direkt/service-tools/specialist-search';
import { provideStudentInsuranceCheckServiceTool } from '@big-direkt/service-tools/student-insurance-check';
import { provideToothReplacementSubsidyServiceTool } from '@big-direkt/service-tools/tooth-replacement-subsidy';
import { provideVaccinationCheckServiceTool } from '@big-direkt/service-tools/vaccination-check';
import { provideVaccinationTravelServiceTool } from '@big-direkt/service-tools/vaccination-travel';
import { mobileAppStore } from '@big-direkt/state/mobile-app';
import { uiStore } from '@big-direkt/state/ui';
import { UserModule, userStore } from '@big-direkt/state/user';
import {
    CustomErrorHandlerService,
    DarkModeService,
    darkmodeStore,
    EnvironmentService,
    FeatureFlagsService,
    featureFlagsStore,
    LogSentryInterceptor,
    responseInterceptor,
    STATIC_ENVIRONMENT,
} from '@big-direkt/utils/environment';
import { provideTranslocoRoot } from '@big-direkt/utils/i18n';
import { TranslocoService } from '@jsverse/transloco';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { CanActivateBlacklistedUrlsGuard, CanActivatePhoneNumbersGuard } from './guards';

registerLocaleData(localeDE);

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        // We need to pre fetch our translations in order to use the pipe.
        // loading de uses the default assets/de.json file, only those translations are currently pre fetched
        // See ticket BIGDEV-3434 for context information why we had to do this
        provideAppInitializer(async () => {
            const initializerFn = (
                (translocoService: TranslocoService): (() => Promise<void>) =>
                    /* eslint-disable @stylistic/ts/indent */
                    async () =>
                        firstValueFrom(translocoService.load('de')).then()
            )(inject(TranslocoService));

            return initializerFn();
        }),
        provideAppInitializer(async () => {
            const initializerFn = (
                (
                    environmentService: EnvironmentService,
                    featureFlagService: FeatureFlagsService,
                    navService: NavService,
                    darkmodeService: DarkModeService,
                ): (() => Promise<void>) =>
                    /* eslint-disable @stylistic/ts/indent */
                    async () =>
                        firstValueFrom(environmentService.load()).then(async () => {
                            await Promise.all([
                                firstValueFrom(featureFlagService.load()),
                                navService.initHeaderMenus([
                                    { name: 'top', type: 'big-header-top' },
                                    { name: 'tools', type: 'big-header-tools' },
                                    { name: 'main', type: 'big-header-main' },
                                    { name: 'user', type: 'my-big' },
                                ]),
                                navService.initFooterMenus([
                                    { name: 'about', type: 'big-footer-about' },
                                    { name: 'accessibility', type: 'big-footer-accessibility' },
                                    { name: 'apps', type: 'big-footer-apps' },
                                    { name: 'contact', type: 'big-footer-contact' },
                                    { name: 'languages', type: 'big-footer-languages' },
                                    { name: 'legal', type: 'big-footer-legal' },
                                    { name: 'mobile', type: 'big-footer-mobile' },
                                    { name: 'social', type: 'big-footer-social' },
                                ]),
                            ]);

                            darkmodeService.handleDarkMode();
                        })
            )(inject(EnvironmentService), inject(FeatureFlagsService), inject(NavService), inject(DarkModeService));

            return initializerFn();
        }),
        { provide: STATIC_ENVIRONMENT, useValue: environment },
        CanActivateBlacklistedUrlsGuard,
        CanActivatePhoneNumbersGuard,
        provideAnimations(),
        provideClientHydration(),
        provideHttpClient(withInterceptors([responseInterceptor]), withFetch(), withInterceptorsFromDi()),
        provideLoadingBarInterceptor(), // Remove this once we have a proper loading bar implementation in file-upload.component.ts
        provideLoadingBarRouter(),
        provideTranslocoRoot(),
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withPreloading(PreloadAllModules)),
        // TODO: optimize, some modules have provider
        importProvidersFrom(ChatClientModule),
        importProvidersFrom(RestApiClientModule),
        importProvidersFrom(JsonApiClientModule),
        importProvidersFrom(NgOverlayContainerModule),
        importProvidersFrom(UserModule),

        {
            provide: 'persistStorage',
            useValue: persistState(featureFlagsStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(darkmodeStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(userStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(mobileAppStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(uiStore, {
                key: 'app',
                storage: sessionStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogSentryInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },

        // Nodes
        provideNodeBigAccordion(),
        provideNodeBigAccordionItem(),
        provideNodeBigArticle(),
        provideNodeBigDashboard(),
        provideNodeBigEpq(),
        provideNodeBigError(),
        provideNodeBigForm(),
        provideNodeBigFormOverview(),
        provideNodeBigLegal(),
        provideNodeBigMyBig(),
        provideNodeBigNews(),
        provideNodeBigPersonalData(),
        provideNodeBigPublicDelivery(),
        provideNodeBigStartPage(),
        provideNodeBigTaggedArticle(),
        provideNodeBigTaggedArticleOverview(),
        provideNodeBigTemplate(),
        provideNodeBigTopBar(),
        provideNodeQuickLink(),

        // Paragraphs
        provideAccordionParagraph(),
        provideAppliancesSearchParagraph(),
        provideBackLinkParagraph(),
        provideBgmButtonParagraph(),
        provideBigtionaerParagraph(),
        provideButtonParagraph(),
        provideC2aBannerParagraph(),
        provideCardGroupParagraph(),
        provideChatParagraph(),
        provideCoBrowsingButtonParagraph(),
        provideContactDataParagraph(),
        provideCookiebotParagraph(),
        provideCustomerSegmentLinksParagraph(),
        provideDashboardBigtionaerParagraph(),
        provideDiagnosisCodeSearch(),
        provideFilterableLinkListParagraph(),
        provideFormOverviewParagraph(),
        provideGapRemoverParagraph(),
        provideHeadingParagraph(),
        provideIframeParagraph(),
        provideImageCreditsParagraph(),
        provideImageParagraph(),
        provideInstructionParagraph(),
        provideInsuranceServicesParagraph(),
        provideLinkListParagraph(),
        provideLinkParagraph(),
        provideListParagraph(),
        provideMyBigGeneralParagraph(),
        provideMyBigInboxParagraph(),
        provideMyBigMedicalAppointmentParagraph(),
        provideMyBigSickNotesParagraph(),
        provideNewsTeaserParagraph(),
        providePersonalDataParagraph(),
        providePublicDeliveryParagraph(),
        provideQuickLinkListParagraph(),
        provideSearchableLinkListParagraph(),
        provideSearchBoxParagraph(),
        provideServiceToolParagraph(),
        provideSubjectAreasParagraph(),
        provideTableParagraph(),
        provideTextButtonParagraph(),
        provideTextParagraph(),
        provideUserDataParagraph(),
        provideUserDetailsParagraph(),
        provideVideoGroupParagraph(),
        provideWebformParagraph(),

        // Service Tools
        provideAdvantageCalculatorServiceTool(),
        provideContributionCalculatorServiceTool(),
        provideCoPaymentCalculatorServiceTool(),
        provideDiabetesDiagnosisServiceTool(),
        provideFamilyHealthRetreatSearchServiceTool(),
        provideHomeopathyDoctorSearchServiceTool(),
        providePreventionCheckServiceTool(),
        provideSpecialistSearchServiceTool(),
        provideStudentInsuranceCheckServiceTool(),
        provideToothReplacementSubsidyServiceTool(),
        provideVaccinationCheckServiceTool(),
        provideVaccinationTravelServiceTool(),

        // Forms
        // Fields
        provideFormViewAddress(),
        provideFormViewAlert(),
        provideFormViewCheckbox(),
        provideFormViewCheckboxes(),
        provideFormViewContactFormSearch(),
        provideFormViewCurrencyInput(),
        provideFormViewDate(),
        provideFormViewDateRange(),
        provideFormViewDateList(),
        provideFormViewDateTime(),
        provideFormViewFileUpload(),
        provideFormViewHidden(),
        provideFormViewHin(),
        provideFormViewIFrame(),
        provideFormViewMarkup(),
        provideFormViewMobileAppInfo(),
        provideFormViewNavigationButton(),
        provideFormViewNumberField(),
        provideFormViewOneTimeToken(),
        provideFormViewPassword(),
        provideFormViewPasswordConfirm(),
        provideFormViewPrivacyPolicy(),
        provideFormViewQuantitySelect(),
        provideFormViewRadio(),
        provideFormViewRadios(),
        provideFormViewSearchableSelect(),
        provideFormViewSelect(),
        provideFormViewTextarea(),
        provideFormViewTextfield(),
        provideFormViewTime(),
        provideFormViewTimeRange(),

        // Preview
        provideFormViewPreview(),

        // Structures
        provideFormViewComposite(),
        provideFormViewContainer(),
        provideFormViewElementsHolder(),
        provideFormViewEmbeddedForm(),
        provideFormViewFieldGroup(),
        provideFormViewFieldSet(),
        provideFormViewForm(),
        provideFormViewFormRelation(),
        provideFormViewItem(),
        provideFormViewItemCollection(),
        provideFormViewLabel(),
        provideFormViewMultiple(),
        provideFormViewMultipleItem(),
        provideFormViewPage(),
        provideFormViewPdfDownload(),
        provideFormViewProgressBar(),
    ],
};
