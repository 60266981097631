import { isDevMode, makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { TranslocoLoaderService } from './services/transloco-loader.service';

export const provideTranslocoRoot = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        ...provideTransloco({
            config: {
                availableLangs: ['de'],
                defaultLang: 'de',
                prodMode: !isDevMode(),
            },
            loader: TranslocoLoaderService,
        }),
        // You have to provide messageformat after provideTransloco. provideTransloco provides its own transpiler and
        // will otherwise overwrite the custom transpiler
        provideTranslocoMessageformat(),
    ]);
