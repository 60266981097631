import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import { type DiabetesCheckupComponent } from '../components/diabetes-checkup.component';
import { type PrediabetesCheckupComponent } from '../components/prediabetes-checkup.component';
import { type RiskDiagnosisComponent } from '../components/risk-diagnosis.component';

export const provideDiabetesDiagnosisServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/prediabetes-checkup.component'),
                id: 'prediabetes-checkup',
            } as ServiceToolsMapItem<PrediabetesCheckupComponent>,
            multi: true,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/risk-diagnosis.component'),
                id: 'diabetes-risk-diagnosis',
            } as ServiceToolsMapItem<RiskDiagnosisComponent>,
            multi: true,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/diabetes-checkup.component'),
                id: 'diabetes-checkup',
            } as ServiceToolsMapItem<DiabetesCheckupComponent>,
            multi: true,
        },
    ]);
