@if (!hasErrors()) {
    <big-ui-footer>
        <div class="grid grid-cols-12 mb-2 gap-x-3">
            <div class="order-1 col-span-12 md:border-r-2 md:border-gray-300 lg:border-r-0 md:col-span-4 lg:col-span-3 md:block">
                <big-ui-footer-menu
                    [menu]="menus?.contact"
                    [isContactMenu]="true"
                    [iconMap]="iconMap" />
            </div>
            <div class="order-2 col-span-12 md:border-r-2 md:border-gray-300 lg:border-r-0 md:col-span-4 lg:col-span-3 md:block">
                <big-ui-footer-menu [menu]="menus?.about" />
            </div>
            <div class="order-3 col-span-12 md:col-span-4 lg:col-span-3 md:block">
                <big-ui-footer-menu [menu]="menus?.legal" />
            </div>
            <div
                class="grid order-4 grid-cols-12 col-span-12 mb-2 gap-x-3 md:order-5 md:border-r-2 md:border-gray-300 lg:border-r-0 md:col-span-8 lg:col-span-3 md:mt-10 lg:mt-0">
                @if (menus?.accessibility; as menu) {
                    <div class="col-span-12 md:col-span-6 lg:col-span-12 md:border-r-2 md:border-gray-300 lg:border-r-0 md:pr-3 lg:pr-0">
                        <big-ui-footer-menu [menu]="menu" />
                    </div>
                }
                @if (menus?.languages; as menu) {
                    <div class="col-span-12 md:col-span-6 lg:col-span-12 lg:mt-6 md:pl-3 lg:pl-0">
                        <big-ui-footer-menu [menu]="menu" />
                    </div>
                }
            </div>
            <div class="justify-between order-5 col-span-12 border-gray-300 md:flex md:pt-6 md:mt-10 md:order-6 md:border-t-2">
                @if (menus?.apps; as menu) {
                    <big-ui-footer-menu
                        [orientation]="'horizontal'"
                        [headerClass]="'shrink-0'"
                        [listClass]="'mt-4 md:mt-0'"
                        [menu]="menus?.apps"
                        [iconMap]="iconMap" />
                }

                @if (menus?.social) {
                    <big-ui-footer-menu
                        [orientation]="'horizontal'"
                        [headerClass]="'md:sr-only'"
                        [listClass]="'lg:text-right mt-4 md:mt-0'"
                        [menu]="menus?.social"
                        [iconMap]="iconMap" />
                }
            </div>
        </div>
        <div class="md:hidden">
            <ul class="flex">
                @for (item of menus?.mobile?.items; track $index) {
                    <li class="after:content-['|'] last:after:content-none after:mx-2">
                        <big-ui-link
                            [href]="item.uri"
                            [linkClass]="'no-underline'">
                            {{ item.title }}
                        </big-ui-link>
                    </li>
                }
            </ul>
        </div>
    </big-ui-footer>
}
