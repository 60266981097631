import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MyBigMedicalAppointmentModel } from '../models/mybig-medical-appointment.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MybigMedicalAppointmentMapper extends ResourceMapper<MyBigMedicalAppointmentModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigMedicalAppointment;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(): MyBigMedicalAppointmentModel {
        return {
            type: ParagraphTypes.MyBigMedicalAppointment,
        };
    }
}
