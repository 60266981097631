import { ChangeDetectionStrategy, Component, HostBinding, inject, signal } from '@angular/core';
import { NavService } from '@big-direkt/nav';
import { UiFooterComponent, UiFooterMenuComponent } from '@big-direkt/ui/footer';
import { UiLinkModule } from '@big-direkt/ui/link';
import {
    IconBigBrandApple,
    IconBigBrandFacebook,
    IconBigBrandGoogle,
    IconBigBrandInstagram,
    IconBigBrandTikTok,
    IconBigBrandYoutube,
    IconBigMediumBriefumschlag,
    IconBigMediumChat,
    IconBigMediumDaumenHoch,
    IconBigMediumKarte,
    IconBigMediumTelefon,
    IconBigMediumTelefon24H,
    IconMap,
} from '@big-direkt/utils/icons';

@Component({
    selector: 'big-footer',
    templateUrl: './footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiFooterComponent, UiFooterMenuComponent, UiLinkModule],
})
export class FooterComponent {
    private readonly navService = inject(NavService);

    @HostBinding('class') public classList = 'block print:hidden';

    public menus = this.navService.footerMenus;
    public hasErrors = signal(false);
    public iconMap: IconMap = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-facebook': IconBigBrandFacebook,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-instagram': IconBigBrandInstagram,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-tik-tok': IconBigBrandTikTok,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-youtube': IconBigBrandYoutube,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-google': IconBigBrandGoogle,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-brand-apple': IconBigBrandApple,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-briefumschlag': IconBigMediumBriefumschlag,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-chat': IconBigMediumChat,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-telefon': IconBigMediumTelefon,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-telefon-24-h': IconBigMediumTelefon24H,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-daumen-hoch': IconBigMediumDaumenHoch,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'big-medium-karte': IconBigMediumKarte,
    };
}
