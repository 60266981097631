import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { RESOURCE_PATH_TOKEN, ResourceTypes, MapperInjectorService as RestApiMapperInjectorService } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MedicalAppointmentMapper } from './mapper/medical-appointment.mapper';
import { MybigMedicalAppointmentMapper } from './mapper/mybig-medical-appointment.mapper';

export const provideMyBigMedicalAppointmentParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.MedicalAppointment]: 'api/medical-appointments' }, multi: true },
        { provide: RestApiMapperInjectorService.tokens[MedicalAppointmentMapper.type], useClass: MedicalAppointmentMapper },
        { provide: MapperInjectorService.tokens[MybigMedicalAppointmentMapper.type], useClass: MybigMedicalAppointmentMapper },

        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./components/mybig-medical-appointment.component'),
                type: ParagraphTypes.MyBigMedicalAppointment,
            },
            multi: true,
        },
    ]);
