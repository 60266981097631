import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MyBigSickNotesMapper } from './mybig-sick-notes.mapper';

export const provideMyBigSickNotesParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.SickNotes]: '/users/insurant/sick-notes' }, multi: true },
        { provide: MapperInjectorService.tokens[MyBigSickNotesMapper.type], useClass: MyBigSickNotesMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./mybig-sick-notes.component'),
                type: ParagraphTypes.MyBigSickNotes,
            },
            multi: true,
        },
    ]);
