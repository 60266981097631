import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { isDefined } from '@big-direkt/utils/shared';
import { type ObligationStatus } from '../models/obligation-status.model';
import { type VaccinationResult , type Vaccination } from '../models/vaccination-result.model';
import { type MandatoryResource, type VaccinationResource, type VaccinationTravelCountryResource } from '../models/vaccination-travel-country.resource';

const YELLOWFEVER_VACCINATION_ID = '1f442dceebcf6e95489174cd469b9fb6';
const POLIO_VACCINATION_ID = '99d4d507698e5c407ba259bed1bc0477';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class VaccinationTravelCountryMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.VaccinationTravelCountry;

    public map(resource: VaccinationTravelCountryResource): VaccinationResult {
        const { country, vaccinations } = resource.attributes;

        return {
            country: country.name,
            mandatoryVaccinations: this.getVaccinationsById(country.vaccinations.mandatory, vaccinations),
            allInclusiveVaccinations: this.getVaccinationsById(country.vaccinations.recommended_for_all_inclusive_travelers, vaccinations),
            adventureVaccinations: this.getVaccinationsById(country.vaccinations.recommended_for_adventure_travelers, vaccinations),
            malariaInformationText: country.malaria ?? undefined,
            yellowFeverVaccinationObligation: this.checkObligationStatus(YELLOWFEVER_VACCINATION_ID, country.vaccinations.mandatory),
            polioVaccinationObligation: this.checkObligationStatus(POLIO_VACCINATION_ID, country.vaccinations.mandatory),
            polioEntryRegulation: country.travel_entry_regulations_polio,
            meningitisEntryRegulation: country.travel_entry_regulations_meningitis,
        };
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private getVaccinationsById(vaccinationIds: { vaccination_uid: string }[], vaccinations: VaccinationResource[]): Vaccination[] {
        return vaccinationIds.map(vaccination => this.getVaccination(vaccination.vaccination_uid, vaccinations)).filter(isDefined);
    }

    private checkObligationStatus(id: string, mandatoryVaccinations: MandatoryResource[]): ObligationStatus | undefined {
        const result = mandatoryVaccinations.find(vacc => vacc.vaccination_uid === id);

        if (!result) {
            return undefined;
        }

        return result.is_mandatory === 'Y' ? 'mandatory' : 'optional';
    }

    private getVaccination(vaccinationId: string, vaccinations: VaccinationResource[]): Vaccination | undefined {
        const vaccinationResource = vaccinations.find(vaccination => vaccination.uid === vaccinationId);

        if (!vaccinationResource) {
            return undefined;
        }

        return {
            id: vaccinationResource.uid,
            description: vaccinationResource.description,
            name: vaccinationResource.name,
        };
    }
}
