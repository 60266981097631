import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { UiLinkModule } from '@big-direkt/ui/link';
import {
    IconBigMediumPlus,
    IconComponent,
    IconMapToIconPipe,
    type IconMap,
} from '@big-direkt/utils/icons';
import { BreakpointService, MenuModel } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-footer-menu',
    templateUrl: './footer-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, IconComponent, UiLinkModule, IconMapToIconPipe],
})
export class FooterMenuComponent {
    public readonly iconPlus = IconBigMediumPlus;
    private readonly breakpointService = inject(BreakpointService);

    @Input() public menu!: MenuModel;
    @Input() public orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() public isContactMenu = false;

    /**
     * CSS class for the header element
     */
    @Input() public headerClass?: string;

    /**
     * CSS class for the list element
     */
    @Input() public listClass?: string;

    /**
     * If you use icons in you navigation, you can pass them with this
     */
    @Input() public iconMap?: IconMap;

    @Input() public isActive = false;

    public isMobile = this.breakpointService.isMobile;
}
