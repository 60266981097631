import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { MedicalAppointmentDocument, MedicalAppointmentModel } from '../models/medical-appointment.model';
import { MedicalAppointmentDocumentResource, MedicalAppointmentResource } from '../models/medical-appointment.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class MedicalAppointmentMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.MedicalAppointment;

    private readonly locale = inject(LOCALE_ID);

    public map(resource: MedicalAppointmentResource): MedicalAppointmentModel {
        return {
            totalCount: resource.attributes.data.appointments.length,
            documents: this.mapDocuments(resource.attributes.data.appointments),
        };
    }

    private mapDocuments(documents: MedicalAppointmentDocumentResource[]): MedicalAppointmentDocument[] {
        if (!documents.length) {
            return [];
        }

        return documents.map(document => ({
            createdDate: new Date(document.created_date),
            status: document.status,
            speciality: document.speciality ?? undefined,
            doctorFirstname: document.doctor_firstname ?? undefined,
            doctorLastname: document.doctor_lastname ?? undefined,
            doctorAddress: document.doctor_address ?? undefined,
            doctorZipCode: document.doctor_zip_code ?? undefined,
            doctorCity: document.doctor_city ?? undefined,
            doctorPhoneNumber: document.doctor_phone_number ?? undefined,
            scheduledDate: document.scheduled_date ? new Date(document.scheduled_date) : undefined,
            desiredDate: document.desired_date ? new Date(document.desired_date) : undefined,
            desiredTime: document.desired_time ?? undefined,
            alternativeDate: document.alternative_date ? new Date(document.alternative_date) : undefined,
            alternativeTime: document.alternative_time ?? undefined,
        }));
    }
}
